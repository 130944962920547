import React from 'react';
import PropTypes from 'prop-types';

import { Section, Layout, PageWrap } from 'src/modules/layout';
import { colors } from 'src/styles';

const TermsPageTemplate = ({ html }) => (
  <Layout headOptions={{ title: 'Όροι χρήσης' }} footerOptions={{ backgroundColor: colors.background.lightblue }}>
    <Section withPadding>
      <PageWrap>
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </PageWrap>
    </Section>
  </Layout>
);

TermsPageTemplate.propTypes = {
  html: PropTypes.string.isRequired,
};

export default TermsPageTemplate;
