import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import TermsPageTemplate from './terms/terms.Template';

const TermsPage = ({ data }) => {
  const { html } = data.markdownRemark;
  return <TermsPageTemplate html={html} />;
};

TermsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default TermsPage;

export const pageQuery = graphql`
  query TermsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "terms-page" } }) {
      html
    }
  }
`;
